import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const GetDefaults = () => {
    const { site: { siteMetadata } } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
    `)

    return {
        ...siteMetadata,
    }
}

const Meta = ({
    seo,
}) => {
    const defaults = GetDefaults()

    const title = seo?.title ?? defaults?.title
    const description = seo?.description ?? defaults?.description

    const ogTitle = seo?.opengraphTitle ?? defaults?.title
    const ogDescription = seo?.opengraphDescription ?? defaults?.description
    const ogImage = seo?.opengraphImage?.sourceUrl || null
    
    const twitterTitle = seo?.twitterTitle ?? defaults?.title
    const twitterDescription = seo?.twitterDescription ?? defaults?.description
    const twitterImage = seo?.twitterImage?.sourceUrl || null

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />

            <meta property="og:title" content={ogTitle} />
            <meta property="og:description" content={ogDescription} />
            <meta property="og:image" content={ogImage} />
            
            <meta name="twitter:title" content={twitterTitle} />
            <meta name="twitter:description" content={twitterDescription} />
            <meta name="twitter:image" content={twitterImage} />

            {defaults?.tags?.map(({ tagName, attributes }, i) => (tagName === 'link') && (
                <link
                    key={i}
                    href={attributes?.href}
                    rel={attributes?.rel}
                    sizes={attributes?.sizes}
                />
            ))}
        </Helmet>
    )
}

export default Meta
