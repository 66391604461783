export default ({
    colors,
    spaces,
    breakpoints,
    gradients,
}) => ({
    key: { name: 'PostArchiveRelatedView' },
    
    buttons: {
        padding: [spaces[5], 0],
    },

    buttonsSet: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    related: {
        padding: [spaces[5], 0, spaces[5], 0],
        position: 'relative',
        backgroundColor: colors.tertiary,
        color: colors.white,
        overflow: 'hidden',

        '&::before': {
            display: 'block',
            width: '150%',
            height: '60%',
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            background: gradients.radial.top.blue,
            content: '""',
            zIndex: 1,
            opacity: .8,
        },
    },

    container: {
        position: 'relative',
        zIndex: 2,
    },

    title: {
        marginBottom: spaces[4],
        color: colors.white,
    },

    backgroundText: {
        top: -spaces[4],
        left: -spaces[4],
    },

    posts: {
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        margin: [0, -spaces[0]/2],
    },

    post: {
        width: '100%',
        maxWidth: '100%',
        padding: spaces[0]/2,

        [breakpoints.sm]: {
            width: '50%',
            maxWidth: '50%',
        },
    },
})
