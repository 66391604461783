import React, { useState, useEffect} from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import cx from 'classnames'
import is from 'react-jss'

import PostArchiveCard from '../PostArchiveCard'
import BackgroundText from '../../../BackgroundText'

import style from './style'

const PostArchiveRelated = ({
    classes,
    posts = []
}) => {

    return posts.length > 0 ? (
        <div className={classes.related}>
            <div className={ cx('container', classes.container) }>
                <BackgroundText {...{ text: [ 'Related', 'News' ], white: true, className: classes.backgroundText }} />

                <div className="row tac">
                    <div className="col-xs-12">
                        <h2 className={cx(classes.title)}>Related News</h2>
                    </div>

                    <div className="col-xs-12">
                        <div className={classes.posts}>
                            { posts.map((post, i) => (
                                <div className={classes.post} key={i}>
                                    <PostArchiveCard key={i} index={i+1} post={post} />
                                </div>
                            )) }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null
}

export default is(style)(PostArchiveRelated)