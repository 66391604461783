export default ({
	colors,
	spaces,
	breakpoints,
}) => ({
	key: { name: 'BackToTop' },

	backToTop: {
		background: colors.white,
		padding: [spaces[3], 0, spaces[4]],
		
		[breakpoints.md]: {
			padding: [spaces[4], 0, spaces[5]],
		},
	},

	buttons: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',

		[breakpoints.sm]: {
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
	},

	topButton: {
		marginTop: spaces[2],

		[breakpoints.sm]: {
			marginTop: 0,
		},
	},

	icon: {
		margin: [0, spaces[1], 0, 0],
		transform: 'translateY(-2px)',
		verticalAlign: 'middle',
		fontSize: 22,
		color: colors.white,

		'&.top': {
			margin: [0, 0, 0, spaces[1]],
		},
	},
})
