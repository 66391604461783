import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import is from 'react-jss'

import Button from '../Button'
import Link from '../Link'

import style from './style'

const BackToTop = ({
	classes,
}) => {
	const clickHandler = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	return (
		<div className={classes.backToTop}>
			<div className="container">
				<div className="row">
					<div className="col-xs-12">
						<div className={cx(classes.buttons)}>
							<Link to="/news/">
								<Button color="black" className={classes.backBtn}>
									<FontAwesomeIcon icon={[ 'far', 'angle-left' ]} color="black" className={cx(classes.icon, 'back')} />
									Back to news
								</Button>
							</Link>
							<Button {...{ color: 'black', clickHandler }} className={classes.topButton}>
								Back to top
								<FontAwesomeIcon icon={[ 'far', 'angle-up' ]} color="black" className={cx(classes.icon, 'top')} />
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default is(style)(BackToTop)